@import '../../styles/variables.scss';

.terminos-container {
	display: flex;
	flex-direction: column;
	padding-top: 60px;
	padding-bottom: 60px;
	justify-content: center;

	@media only screen and (max-width: $screen-md-min) {
		flex-direction: column;
	}
}

.imageCambioPlanes{
	display:block;
	margin-left: auto;
	margin-right: auto;
}

.planes{
	p, ul{
		margin-bottom: 30px;

		&:last-child{
			margin: 0;
		}
	}
	ul{
		li:before{
			content: "\2022";
			color: $fulcro-green;
			margin-right: 6px;;
		}
	}
}
