@import '../../styles/variables.scss';
@import '../../styles/accordian.scss';
@import '../../styles/jump-nav.scss';

.detailJob {
	font-size: 1.6rem;
}

.listJob {
	font-size: 1.6rem;
}

.empleos {
	.accordion__heading {
		p {
			font-size: 1.6rem;
			font-family: 'TradeGothicLT';
			margin: 6px 0;
		}
	}

	.accordion__panel {
		p,
		ul {
			margin-bottom: 30px;

			&:last-child {
				margin: 0;
			}
		}

		ul {
			li:before {
				content: '\2022';
				color: $fulcro-green;
				margin-right: 6px;
			}
		}
	}
}

.titleJobs {
	font-family: 'Tahoma-Bold', sans-serif;
	font-size: 16px;
	font-weight: bold;
}
.blackLink {
	color: $fulcro-black;
	font-size: 1rem;
	font-family: 'Tahoma-Bold', sans-serif;
	letter-spacing: -0.05rem;
}

.accordion__button {
	&::after {
		background: none !important;
	}
}

.accordion__item__button__politicas {
	padding:  10px 0 32px 0;
}
