@font-face {
	font-family: 'Tahoma';
	src: url('./fonts/Tahoma/Tahoma.woff') format('woff');
}
@font-face {
	font-family: 'Lato-Black';
	src: url('./fonts/Lato/Lato-Black.woff2') format('woff2'),
		url('./fonts/Lato/Lato-Black.woff') format('woff');
}

@font-face {
    font-family: 'Tahoma-Bold';
    src: url('./fonts/Tahoma/tahomabd.ttf');
}

@font-face {
	font-family: 'Lato-Italic';
	src: url('./fonts/Lato/Lato-Italic.woff2') format('woff2'),
		url('./fonts/Lato/Lato-Italic.woff') format('woff');
}
@font-face {
	font-family: 'Lato-Light';
	src: url('./fonts/Lato/Lato-Light.woff2') format('woff2'),
		url('./fonts/Lato/Lato-Light.woff') format('woff');
}
@font-face {
	font-family: 'Lato-Medium';
	src: url('./fonts/Lato/Lato-Medium.woff2') format('woff2'),
		url('./fonts/Lato/Lato-Medium.woff') format('woff');
}
@font-face {
	font-family: 'Lato-Semibold';
	src: url('./fonts/Lato/Lato-Semibold.woff2') format('woff2'),
		url('./fonts/Lato/Lato-Semibold.woff') format('woff');
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-size: 14px;
	color: #313B46;
	font-family: 'Tahoma', sans-serif;
}
