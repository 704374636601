@import '../../styles/variables.scss';

.contacto__inner {
	padding: 53px 0 80px 0;
	max-width: 788px;
	margin: 0 auto;
	@media (min-width: $screen-md-min) {
		padding: 100px 0 80px 0;
	}

	.container-left {
		margin-bottom: 40px;

		@media only screen and (min-width: $screen-md-min) {
			margin: 0;
		}

		form {
			@media only screen and (min-width: $screen-md-min) {
				display: grid;
				column-gap: 32px;
				grid-template-columns: 50% 50%;
			}
			&.contacto-form-container {
				margin-top: 10px;
			}
			.contacto-textarea {
				grid-column: span 2;
			}

			.container-subscribete-container {
				grid-column: span 2;

				margin: 20px 0 40px 0;
				vertical-align: middle;
				line-height: 15px;
				letter-spacing: 0.25px;

				&-checkbox {
					display: inline-block;
				}
			}
			.button__submit {
				grid-column: span 2;

				width: 160px;
				margin-left: auto;
			}
		}
	}

	.container-right {
		margin-bottom: 40px;

		@media only screen and (min-width: $screen-md-min) {
			margin: 0;
		}
	}
}

.paiz-contacto {
	margin: 35.89px auto 0 auto;

	h2 {
		font-size: 20px;
		line-height: 27px;
		letter-spacing: 0.15px;
	}

	address {
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.25px;
		color: $fulcro-text;
		font-style: normal;
		padding-bottom: 100px;
		a {
			font-family: 'Tahoma';
		}
	}
}

.mapa {
	width: 100%;
	height: 377.53px;
	@media only screen and (min-width: $screen-md-min) {
		height: 512px;
	}
}
