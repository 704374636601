@import '../../styles/variables.scss';

.funnel-resumen-content-slider {
	position: relative;
	background-color: #006FA7;
	box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
	.funnel-resumen-top-div {
		background-color: $white;
		border-bottom: $fulcro-gray solid 1px;
		padding: 28px;
		text-align: left;
		max-width: 100%;
		@media only screen and (max-width: $screen-md-min) {
			padding: 0px 28px 15px 28px;
		}
	}
	
	.funnel-resumen-h4 {
		font-family: 'Tahoma-Bold';
		letter-spacing: 0;
		text-align: left;
	}
	
	.funnel-resumen-pago {
		color: $fulcro-gray-2;
		font-size: 16px;
		letter-spacing: 0;
		span {
		}
	}
	
	.funnel-resumen-bottom-div {
		padding: 28px;
		text-align: left;
		max-width: max-content;
	}
	
	.funnel-resumen-bottom-div ul {
		display: block;
		text-align: left;
		margin-top: 16px;
		text-transform: none;
		list-style-type: none;
	}
	
	.funnel-resumen-bottom-div ul li::before {
		content: url('../../images/svg/check_white.svg');
		padding: 0 10px 10px 0;
	}
	
	.funnel-resumen-bottom-div li {
		display: flex;
		font-size: $font-12;
		letter-spacing: 0;
		text-decoration: none;
	}
	
	.funnel-resume-link {
		margin-top: 10px;
		color: $fulcro-gray;
		font-size: $font-16;
		display: block;
		text-decoration: underline;
	}
	
	.funnel-resumen-slider-content {
		width: 100%;
		height: 24px;
		display: flex;
		margin-top: 38px;
		margin-bottom: 20px;
		justify-content: center;
	}
	
	.funnel-slider-center {
		width: 80%;
	}
	
	/* Style the button that is used to open and close the collapsible content */
	.collapsible {
		background-color: $white;
		color: #444;
		cursor: pointer;
		padding: 18px;
		width: 100%;
		border: none;
		text-align: left;
		outline: none;
		font-size: 15px;
	}
	
	.wrap-collabsible {
		box-shadow: 0 -2px 32px 0 rgba(21, 13, 0, 0.1);
	}
	
	input[type='checkbox'] {
		display: none;
	}
	
	.lbl-toggle {
		display: flex;
		text-align: left;
		margin-top: 0px;
		padding: 20px 30px;
		cursor: pointer;
		border-radius: 7px;
		transition: all 0.25s ease-out;
		padding-left: 28px;
		justify-content: space-between;
	}
	
	.lbl-toggle::after {
		content: url('../../images/svg/check_white.svg');
		display: inline-block;
		padding-left: 10px;
		margin-right: 0.7rem;
		width: 10px;
		height: 10px;
		transition: transform 0.2s ease-out;
	}
	
	//Esperando como va ser la animacion
	.toggle:checked + .lbl-toggle::after {
		// transform: rotate(180deg) translateY(-10px);
	}
	
	.collapsible-content {
		max-height: 0px;
		overflow: hidden;
		display: none;
		transition: max-height 0.25s ease-in-out;
	}
	
	.toggle:checked + .lbl-toggle + .collapsible-content {
		max-height: 100vh;
		display: block;
	}
	
	.toggle:checked + .lbl-toggle {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	
	.collapsible-content .content-inner {
		background: $white;
		padding: 0.5rem 1rem;
		@media only screen and (max-width: $screen-md-min) {
			padding: 0;
		}
	}
	
	.legend-renovacion {
		font-weight: bold;
		font-size: 12px;
		float: right;
		padding: 10px;
		margin-left: 5px;
		font-style: italic;
		color: $fulcro-gray;
	}
}

