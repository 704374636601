@import '../../../styles/variables.scss';
.header__container__desktop {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
header.desktop {
	display: none;
	width: 100%;
}

@media only screen and (min-width: $bp--desktop-nav) {
	header.header--mobile {
		display: none;
	}

	header.desktop {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 9;
		padding: 12px 55px;
		background: #ffffff;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
		nav {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		a.button {
			width: auto;
			padding: 10px 55px;
		}
	}

	.emergencia__desktop {
		display: none;
		justify-content: space-between;
		align-items: center;
		font-size: 0.875rem;
		font-family: 'Tahoma';

		&__links {
			white-space: nowrap;

			a,
			.link {
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				text-align: right;
				cursor: pointer;

				&:first-child {
					margin-left: 4px;
				}

				img {
					display: block;
				}

				&.close {
					margin-left: 1.081rem;

					img {
						display: inline-block;
						vertical-align: middle;
						filter: contrast(100) invert(1);

						&:hover {
							opacity: 80%;
						}
					}
				}
			}
		}
		&__title {
			margin-right: 60px;
		}
	}

	.emergencia__desktop.toast {
		display: flex;
		background: $fulcro-black;
		color: $white;
		padding: 15px 27px 15px 20px;
		border-radius: 40px;
		z-index: 3;
		position: absolute;
		bottom: -84px;
		transition: all 0.1s ease-in;

		&.on--scroll {
			right: 0;
			position: fixed;
			top: 0;
			height: fit-content;
			bottom: 0;
			left: 0;
			border-radius: 0;
		}
	}

	.logo {
		img {
			display: block;
			width: 225px;
			height: 28px;
		}
	}

	.header__col {
		display: flex;
		flex: 1;

		&.left {
			margin-right: auto;
			justify-content: flex-start;
			flex-shrink: 0;
		}

		&.right {
			justify-content: flex-end;
			margin-left: auto;
			height: 50px;
			align-items: center;
			.button__link {
				color: $fulcro-black;
				margin-right: 40px;
			}
		}
	}
} // END Media Query
