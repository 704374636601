@import '../../styles/variables.scss';

.exp-content {
	display: flex;
	padding: 20px 0;
	justify-content: space-between;
	align-items: center;
	flex-direction: column-reverse;
	text-align: center;

	.img__container {
		display: flex;
	}

	& > div {
		flex: 0 0 50%;
		width: 100%;

		img {
			width: 200px;
			margin: 0 auto 21.98px auto;
		}

		h2 {
			margin: 0 auto;
			text-align: center;
			font-size: 20px;
			line-height: 27px;
		}
	}

	p {
		font-size: $font-14;
		line-height: 17px;
		text-align: center;
		letter-spacing: 0.25px;
		min-height: 6.912rem;
		max-width: 315px;
		margin: 20px auto 0;
	}
}

.exp-reverse {
	flex-direction: column-reverse;

	img {
		width: 70%;
		@media only screen and (max-width: $screen-md-min) {
			width: 200px;
		}
	}
}

.exp-title {
	color: $fulcro-gray;
	font-size: $font-42;
	font-family: 'Tahoma-Bold', sans-serif;
	line-height: 52px;
	text-align: left;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-22;
		text-align: center;
	}
}

.exp-subTitle {
	color: $light-gray;
	font-size: 1.8rem;
	font-family: 'Lato-Light';
	@media only screen and (max-width: $screen-md-min) {
		font-size: 1.6rem;
	}
}
