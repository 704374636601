@import '../../styles/variables.scss';

.detail__plan {
	&__container {
		border: none;
		border-radius: 4px;
		background-color: $fulcro-black;
		display: flex;
		color: $white;

		&.secondary {
			background-color: $fulcro-black;
			color: $white;
		}

		table {
			border-collapse: collapse;
			width: 100%;
			th {
				text-align: left;
			}
			tr {
				td,
				th {
					padding: 10px 12px;
				}
				border-bottom: 0.25px solid #51b9da;
			}
		}
	}
	&__col {
		flex: 1 1 20%;
		&__big {
			text-align: left;
			flex: 1 1 30%;
		}
		&__header {
			background-color: $fulcro-green-2;
			color: $white;
			border-bottom: 2px solid $white;
			padding: 10px;
			.title {
				font-weight: 500;
				font-size: 18px;
				line-height: 30px;
				letter-spacing: 0.15px;
				&.border {
					border-left: 1px solid rgba(255, 255, 255, 0.5);
					border-right: 1px solid rgba(255, 255, 255, 0.5);
				}
			}
			.subtitle {
				font-size: 14px;
				line-height: 15px;
				letter-spacing: 0.25px;
				font-weight: normal;
				margin-top: 1rem;
				text-align: left;
				display: flex;
			}
		}
		&__content {
			font-weight: 500;
			text-align: left;
			font-family: 'Lato-Medium';
			&.border {
				border-left: 1px solid $fulcro-azul-cielo;
				border-right: 1px solid $fulcro-azul-cielo;
				font-family: 'Lato-Light';
			}
			&.text--center {
				text-align: center;
				font-family: 'Lato-Light';
			}

			& > div {
				padding: 10px 12px;
				border-bottom: 0.25px solid #51b9da61;
				min-height: 55px;
			}
		}
	}
}
