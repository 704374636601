@import '../../../styles/variables.scss';

// All FunnelPages  Styles
.step-header {
	margin-top: 30px;
	h1 {
		margin: 0 0 20px 0;
	}
}

.wrap-collabsible {
	label {
		font-size: $font-14;
		font-family: 'Tahoma-Bold', sans-serif;
	}
}

.button--orange.funnel-advance.mobile {
	margin-top: 60px;
}

.border-fill-white {
	border: 1px solid white !important;
}

.fill-white {
	fill: white !important;
}
.fill-transparent {
	fill: transparent !important;
}
.border-fill-red {
	border: 1px solid $fulcro-red !important;
	border-radius: 4px;
}
.background-green {
	background: $fulcro-green;
}
.border-fill-green {
	border: 1px solid $fulcro-green !important;
	border-radius: 4px;
	color: $white;
	height: 20px !important;
	width: 20px !important;
}

// remove page margin from funnel pages
// funnel pages should get shared class
.-cobertura,
.-info,
.-pago,
.-confirmacion,
.-perfil,
.-perfil-hogar {
	.App {
		margin-top: 0 !important;
	}
}

.mas-info {
	font-family: 'Tahoma-Bold', sans-serif;
	font-size: 1rem;
}

// END All FunnelPages  Styles

// Cobertura Styles

.cobertura-content {
	@media only screen and (max-width: $screen-md-min) {
		padding-left: 5%;
		padding-right: 5%;
		padding-top: 36px;
		min-height: auto;
		flex-direction: column;
	}
}

.cobertura-h3 {
	max-width: 582px;
}

.cobertura-direction-column {
	display: flex;
	flex-direction: column;
}

/*
.cobertura-content > div {
	@media only screen and (max-width: $screen-md-min) {
		width: auto;
		padding: 0 0px 0 0px;
	}
}
*/
@at-root .cobertura-necesitas-content {
	margin-top: 3.221rem;
	display: flex;
	justify-content: center;
}

.cobertura-necesitas-div {
	background-color: $cool-blue;
	border-radius: 10px;
	padding: 10px 50px;
}

.cobertura-p {
	margin-bottom: 20px;
	@media only screen and (min-width: $screen-md-min) {
		margin-bottom: 50px;
	}
}

.cobertura-link {
	margin-top: 10px;
	color: $fulcro-green-2;
	font-size: $font-16;
	display: block;
	text-decoration: underline;
}

.cobertura-select-content {
	-webkit-box-shadow: 0px 1px 10px 4px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 1px 10px 4px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 1px 10px 4px rgba(0, 0, 0, 0.1);

	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
		margin-top: 25px;
	}
}

.cobertura-select-items {
	padding: 1.972rem 1.25rem;
	display: flex;
	border-bottom: 1px solid $cool-gray;
	&:last-child {
		border-bottom: none;
	}
}

.cobertura-select-items div {
	width: 100%;
}

.cobertura-select-item-type-content {
	display: flex;
	flex: 0 0 45px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 5px 0 0;
}

.cobertura-select-checkbox-container {
	width: 100%;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $cool-gray;
	padding: 6px 20px 6px 10px;

	.title {
		font-size: 11px;
		letter-spacing: 0;
		line-height: 24px;
		flex: 1 1 100%;
		color: $fulcro-black;
	}
	.checkbox {
		margin: 0 3px;
	}
}

.cobertura-select-item-type-label {
	font-family: 'Tahoma-Bold', sans-serif;
	font-size: $font-14;
	letter-spacing: 0.5px;
	line-height: 20px;
	color: $fulcro-gray;
	display: flex;
	flex: 2 1 14rem;
	flex-direction: row;
	align-items: center;
	padding-left: 5px;

	p {
		font-family: 'Tahoma-Bold', sans-serif;
		font-size: $font-14;
		color: $fulcro-gray;
		margin-bottom: 0;
		@media only screen and (max-width: $screen-md-min) {
			justify-content: flex-end;
			margin-right: 10px;
		}
	}
}

.cobertura-select-item-qty-content {
	display: flex;
	flex: 0 1 80px;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.cobertura-payment-content {
	position: relative;
	z-index: 0;
	display: flex;
	margin-top: 30px;
	align-items: stretch;
	box-shadow: 0px 1px 10px 4px rgba(0, 0, 0, 0.1);
	transition-duration: 0.4s;
}

.cobertura-qty-label {
	max-width: 65px;
	color: $fulcro-gray;
	font-size: $font-18;
	font-family: 'Tahoma-Bold', sans-serif;
	text-align: center;
	@media only screen and (max-width: $screen-md-min) {
		max-width: 50px;
	}
}

.cobertura-payment-option-qty {
	font-family: 'TradeGothicLTStd-BoldExt';
	font-size: $font-28;
	margin-top: 12px;
	text-align: center;
	letter-spacing: 0.88px;
	line-height: 34px;
}

.cobertura-auto {
	height: 1.244rem;
}
