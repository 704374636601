@import '../../../styles/variables.scss';

.service-hero-products-content {
	height: 309.86px;
	
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding-bottom: 60px;
	margin-top: -40px;
	&.auto {
		background-image: url('../../../images/services/service-auto.png');
	}
	&.hogar{
		background-image: url('../../../images/services/service-hogar.png');
	}
	@media only screen and (min-width: $screen-md-min) {
		height: 500px;
		padding-bottom: 65px;
		margin-top: 0;
	}

	.inner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-top: 40px;
		padding-bottom: 40px;
		height: 100%;
	}

	h1 {
		display: flex;
		color: $white;

		@media only screen and (min-width: $screen-md-min) {
			font-size: 45px;
			line-height: 60px;
		}
	}
}

.service-hero-products-content .service-hero-products-back-btn {
	display: inline-flex;
	height: 52px;
	border: solid $white 2px;
	color: $white;
	font-family: 'Tahoma-Bold', sans-serif;
	justify-content: space-between;
	align-items: center;
	font-size: 1.5rem;
	padding: 20px;
	text-transform: uppercase;

	a {
		text-decoration: none;
	}
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 10px;
		margin-left: 10px;
	}
}

.service-hero-icon {
	--size: 20px;
	display: inline-block;
	width: var(--size);
	height: var(--size);
	transition: 0.12s;
	mask-size: cover;
	-webkit-mask-size: contain;
	mask-repeat: no-repeat;
	-webkit-mask-repeat: no-repeat;
}

.service-hero-icon-left-arrow {
	background: $white;
	-webkit-mask-image: url(../../../images/svg/left-arrow.svg);
	mask-image: url(../../../images/svg/left-arrow.svg);
	margin-right: 10px;
}

.service-hero-bottom {
	display: flex;
	justify-content: space-between;
}

.service-hero-bottom div {
	width: 100%;
}

.service-hero-card {
	height: 506px;
	width: 392px !important;
	background-color: $fulcro-green;
	box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}
