.faq__group {
	padding: 30px 0;
	border-top: 1px solid $cool-gray;

	@media (min-width: $bp--md) {
		padding: 40px 0 80px 0;
	}
}

.accordion__item {
	border-top: 1px solid $cool-gray;
	transition: height $fast;
}

.accordion__heading {
	font-family: 'Tahoma-Bold', sans-serif;
	font-size: 1.8rem;
	letter-spacing: -0.05rem;
	cursor: pointer;

	@media (min-width: $bp--md) {
		font-size: 2.4rem;
	}
}

.accordion__button {
	padding: 46px 46px 46px 0;
	position: relative;

	&::after {
		position: absolute;
		right: 0;
		top: 5rem;
		width: 20px;
		height: 20px;
		content: '';
		display: block;
		background: url('/images/svg/plus-icon.svg') center center no-repeat;
		background-size: contain;
		transition: transform $fast;

		@media (min-width: $bp--md) {
			right: 46px;
			width: 26px;
			height: 26px;
		}
	}

	&[aria-expanded='true']::after {
		transform: rotate(45deg);
	}

	@media (min-width: $bp--md) {
		padding: 46px;
	}
}

.accordion__panel {
	padding: 0 0 30px 0;

	@media (min-width: $bp--md) {
		padding: 0 46px 46px;
	}

	p {
		margin: 0;
	}
}

.faq-pad {
	padding: 32px 0;

	@media (min-width: $bp--md) {
		padding: 46px;
	}
}
