@import '../../../styles/variables.scss';

.h1.product__shared {
	color: white;
}

.productos-shared {
	background-color: $fulcro-celeste;
	order: 2;
}
.productos-shared-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.productos-shared-column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
}

.productos-shared-double-column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 2;
}

.productos-shared-blue-column {
	background-color: blue;
	height: 100px;
}

.productos-shared-green-column {
	height: 100px;
	background-color: green;
}

.productos-shared-section-2 {
	margin-top: 40px;

	& > div {
		@media (min-width: $bp--md) {
			padding-right: 500px;
		}
	}

	h2 {
		font-size: 42px;
		letter-spacing: -0.6px;
		line-height: 52px;
		@media only screen and (max-width: $screen-md-min) {
			font-size: 22px;
			letter-spacing: -0.31px;
			line-height: 32px;
			text-align: left;
		}
	}

	p {
		font-family: 'Lato-Light';
		@media only screen and (max-width: $screen-md-min) {
			text-align: left;
		}
	}
}

.carro__fulcro {
	width: 162.662px;
	height: 133.15px;
	&__container {
		display: flex;
		margin: 0 auto 80px auto;

		text-transform: uppercase;
		color: $fulcro-green-2;
		align-items: center;
		
		ul {
			font-family: Tahoma;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
			letter-spacing: 0.75px;
			width: 275.375px;
			@media only screen and (max-width: $screen-md-min) {
				font-size: 10px;
				font-style: normal;
				font-weight: 700;
				line-height: 15px;
			}
			letter-spacing: 0.75px;
			text-transform: uppercase;
			li {
				margin-bottom: 1rem;
			}
			li::before {
				content: url('../../../images/svg/check.svg');
				margin-left: -16px;
				margin-right: 6px;
			}
		}
	}
	@media only screen and (min-width: $screen-md-min) {
		width: 375.628px;
		height: 307.478px;
	}
}

.productos-shared-section-3 {
	padding-bottom: 108.3px;
	padding-top: 79.15px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media only screen and (max-width: $screen-md-min) {
		margin-left: 20px;
		margin-right: 20px;
	}

	h3 {
		color: $fulcro-black;
		margin: 0 0 5px 0;
		padding: 0;
		font-size: 20px;
		letter-spacing: -0.29px;
		line-height: 30px;
	}

	p {
		max-width: 591px;
		color: $fulcro-black;
		@media only screen and (max-width: $screen-md-min) {
			text-align: left;
		}
	}
}

.productos-shared-section-3-items {
	display: flex;
	margin: 30px 0 0 30px;
	flex-direction: column;
	@media only screen and (min-width: $screen-md-min) {
		margin: 80px auto 0 auto;
		flex-direction: row;
	}
}

.productos-shared-section-3-item {
	width: 100%;
	display: flex;
	margin-top: -25px;
	@media only screen and (min-width: $screen-md-min) {
		flex-direction: column;
	}
}

.productos-shared-section-3-item-period {
	position: relative;
	top: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-right: 1.301rem;
	@media only screen and (min-width: $screen-md-min) {
		top: 0;
	}
}

.productos-shared-section-3-item-info {
	padding-top: 17px;
	margin-bottom: 40px;
	max-width: 11.635rem;

	p {
		letter-spacing: 0.25px;
	}

	@media only screen and (min-width: $screen-md-min) {
		max-width: 22.125rem;
		padding-right: 56px;
	}
}

.productos-shared-section-3-item-period-square {
	width: 12px;
	height: 12px;
	box-sizing: content-box;
	border-width: 6px;
	border-style: solid;
	border-color: $fulcro-celeste;
	background-color: $fulcro-green;
	flex-shrink: 0;
}

.productos-shared-section-3-item-period-line {
	width: 1px;
	height: 100%;
	background-color: $fulcro-green;
	display: flex;

	@media only screen and (min-width: $screen-md-min) {
		width: 93%;
		height: 1px;
		position: relative;
		top: -13px;
		right: -53%;
	}
}

.productos-shared-productos-buy-container {
	max-width: 1400px;
	margin: 0 auto;
}

.productos-shared-section-4 {
	padding: 46.5px 10px 34.62px 10px;
	background-color: $fulcro-black;
	order: 1;
	@media only screen and (min-width: $screen-md-min) {
		padding: 100px 10px 100px 10px;
	}
	&-container {
		position: relative;
		max-width: 789px;
		margin: 0 auto;
		padding: 0 22px;
		width: 100%;
		@media only screen and (min-width: $screen-md-min) {
			padding: 0;
		}
		h2 {
			color: $white;
		}

		.img__container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 111px;
			height: 112px;
			background-color: $fulcro-azul-cielo;
			border-radius: 50%;
			margin: 0 0 24.92px auto;

			@media only screen and (min-width: $screen-md-min) {
				position: absolute;
				bottom: 0;
				right: 0;
				margin: 0;
			}
		}
	}
	&-detail {
		max-width: 789px;
		margin: 0 auto;
	}
	p {
		color: $white;
		font-family: 'Lato-Light';
		margin: 40.42px auto 1.75rem 0;
		max-width: 584px;
		letter-spacing: 0.4px;
		&.small {
			font-size: 12px;
			line-height: 15px;
			@media only screen and (min-width: $screen-md-min) {
				font-size: 14px;
				line-height: 17px;
			}
		}
		&.p__include__plan {
			margin: 40.42px auto 3.75rem 0;
		}
	}
}
.productos-shared-falabella-logo {
	margin: 4.736rem 0 0 0;
	display: flex;
	justify-content: center;
}
.productos-shared-section-3 h2 {

	text-transform: uppercase;
	margin-top: 5px;
	@media only screen and (max-width: $screen-md-min) {
		text-align: left;
	}
}

.productos-shared-section-orange-title {
	letter-spacing: 0.75px;
	line-height: 29px;
	text-align: left;
}

.productos-shared-section-4-plans-container {
	margin-top: 103px;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 30px;
	}
}

.productos-shared-section-4-plans-title {
	color: $fulcro-gray;
	font-size: 20px;
	letter-spacing: 0.63px;
	line-height: 24px;
	text-align: left;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-18;
		letter-spacing: 0.56px;
		line-height: 22px;
	}
}

/*
Collapse 
*/

/* Style the button that is used to open and close the collapsible content */
.productos-shared-collapsible {
	background-color: $white;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
}

.productos-shared-wrap-collabsible {
	margin-bottom: 1.2rem 0;
}

input[type='checkbox'] {
	display: none;
}

.productos-shared-lbl-toggle {
	display: flex;
	text-align: left;
	margin-top: 0px;
	padding: 1em 1em 1em 0em;
	cursor: pointer;
	border-radius: 7px;
	transition: all 0.25s ease-out;
}

.productos-shared-lbl-toggle::after {
	content: url('../../../images//svg/chevron-orange.svg');
	display: inline-block;
	margin-top: -3px;
	padding-left: 10px;
	margin-right: 0.7rem;
	width: 10px;
	height: 10px;
	transition: transform 0.2s ease-out;
	transform: rotate(0deg) translateY(0px);
}

//Esperando como va ser la animacion
.toggle:checked + .productos-shared-lbl-toggle::after {
	transform: rotate(180deg) translateY(-20px) translateX(-13px);
}

.productos-shared-collapsible-content {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .productos-shared-lbl-toggle + .productos-shared-collapsible-content {
	max-height: 200vh;
}

.toggle:checked + .productos-shared-lbl-toggle {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.productos-shared-collapsible-content .productos-shared-content-inner {
	background: $white;
	padding: 0.5rem 1rem;
	@media only screen and (max-width: $screen-md-min) {
		padding: 0;
	}
}

/* Sub Header */
.productos-shared-header-sub-container {
	width: 100%;
	height: 56px;
	background-color: $fulcro-green;
	display: flex;
	justify-content: center;
}

.productos-shared-header-sub-container-white {
	background-color: $white;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

@keyframes slide {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

.productos-shared-header-sub-container-fixed {
	position: fixed;
	width: 100%;
	z-index: 11;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	animation-name: slide;
	animation-duration: 0.5s;

	.button {
		width: auto;
		font-size: 1.3rem;
		padding: 0.6rem 1.5rem;
		letter-spacing: 0.05rem;
	}
}

.productos-shared-header-sub-container div {
	width: 100%;
}

.productos-shared-header-sub-left {
	display: flex;
	justify-content: flex-start;
}

.productos-shared-header-sub-right {
	display: flex;
	align-items: center;
	flex-shrink: 3;
	& > img {
		margin-left: 10px;
	}
	.btn {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'Tahoma', sans-serif;
		width: max-content;
		height: 32px;
		background-color: $fulcro-green;
		font-size: $font-13;
		letter-spacing: 0.04px;
		line-height: 16px;
		text-align: center;
		color: $white;
		padding: 5px 9.5px;
	}
}
.productos-shared-header-sub-label {
	font-family: 'Tahoma';
	font-size: $font-14;
	color: $fulcro-black;
	align-self: center;
	text-align: left;
	line-height: 20px;
	letter-spacing: 0.5px;
}

.productos-shared-header-sub-label-gray {
	color: $fulcro-black;
}

.productos-shared-header-sub-img {
	margin-left: 10px;
}

.productos-shared-hide-sub-header {
	display: none;
}
