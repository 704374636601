@import '../../styles/variables.scss';
@import '../../styles/accordian.scss';
@import '../../styles/jump-nav.scss';

.h1__faq {
	color: $fulcro-black;
}

.p__faq {
	font-size: 1rem;
}

.accordion__item__button__faq {
	font-size: 14px;
    padding: 32px 0;
}
