@import '../../../styles/variables.scss';

.productos-buy-container {
	width: calc(100% - 60px);
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-top: -80px;
	margin-bottom: 80px;
	z-index: 4;
	background-color: white;
	text-align: center;
	box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);

	@media (min-width: $bp--lg) {
		float: right;
		right: calc(50% - 600px);
		margin-right: 20px;
		top: 70px;
		margin-top: -418px;
		margin-bottom: 40px;
		z-index: 12;
	}

	&-padding {
		padding: 17px 30px;
		@media only screen and (min-width: $screen-md-min) {
			padding: 20px 40px;
		}
	}
	&-h3 {
		text-align: center;
		color: $fulcro-gray;
		align-items: center;
		white-space: nowrap;
		font-size: $font-18;
		@media only screen and (max-width: $screen-md-min) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&-info {
		text-align: center;
		max-width: 169.63px;
		margin: 10px auto 0 auto;
		line-height: 17px;
		font-size: $font-14;
	}

	&-price {
		text-align: center;
		margin-bottom: 20px;
		& span {
			font-size: $font-25;
			font-family: 'Tahoma-Bold';
		}
	}

	&-btn {
		margin-top: 25px;
		width: 100%;
	}
	&-line {
		margin: 20px 0;
	}
	&-divider {
		height: 1px;
		background: $fulcro-azul-cielo;
		margin: 20px -30px;
		@media only screen and (min-width: $screen-md-min) {
			margin: 20px 0px;
		}
	}
}
