@import '../../../styles/variables.scss';

label.MuiFormLabel-root {
	font-size: 0.875rem;
	color: $fulcro-black;
	font-family: 'Tahoma', sans-serif;
	text-align: left;
}

label.MuiFormLabel-root.MuiInputLabel-shrink {
	color: $fulcro-black;
	font-family: 'Tahoma', sans-serif;
	letter-spacing: 0.25px;
}

label.MuiFormLabel-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.38) !important;
}
.MuiInputBase-input.Mui-disabled {
	opacity: 0.6 !important;
}
.MuiFilledInput-root.Mui-disabled {
	background: transparent !important;
}

input.MuiInputBase-input {
	font-size: $font-14;
}

.stripeInput {
	padding: 20px 12px 10px;
	font-size: 0.875rem;
	box-sizing: content-box;
	width: 100%;
	display: inline-block !important;
	margin-top: 10px;
}

.tooltip {
	&__container {
		cursor: pointer;
		color: $fulcro-green;
		position: absolute;
		right: 10px;
		top: 27px;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			.tooltip__text {
				visibility: visible;
			}
		}
	}

	&__text {
		visibility: hidden;
		width: max-content;
		background-color: #f6f8fa;
		box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);
		color: #717478;
		text-align: center;
		border-radius: 6px;
		padding: 6px;
		position: absolute;
		z-index: 1;
		top: -16px;
		font-size: 11px;
		right: 110%;
		max-width: 200px;
	}
}
