@import '../../../../styles/variables.scss';

.funnel-footer-desktop {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.funnel-footer-desktop-container {
	width: 100%;
	height: 99px;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid $cool-gray;
	background-color: $white;
}

.funnel-footer-desktop-continuar-btn {
	height: 100%;
	width: 392px;
	align-items: center;
	font-size: $font-18;
}

.funnel-footer-desktop-info-div {
	display: flex;
	flex-grow: 2;
	padding-left: 50px;

	a {
		align-self: center;
		width: auto;
	}
}

.funnel-footer-desktop-active {
	color: $fulcro-azul-cielo;
	text-transform: uppercase;
}

.funnel-footer-desktop-disabled {
	color: #8D8E91;
	text-transform: uppercase;
}

.funnel-footer-desktop-ul {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	list-style-type: none;

	li {
		padding: 1em 0;
		margin-right: 28px;
		font-size: $font-18;
		letter-spacing: 0.1rem;
		line-height: 1;
		font-family: 'Tahoma-Bold', sans-serif;

		img {
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.funnel-footer-desktop-left-arrow {
	padding: 10px;
	margin-left: -10px;
	margin-right: 40px;
}

.funnel-footer-desktop-tracking {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $fulcro-gray;
	font-family: 'Tahoma-Bold', sans-serif;
	font-size: $font-20;
	letter-spacing: 0.63px;
	line-height: 24px;
	flex-grow: 1.3;
}

.funnel-footer-desktop-close-btn {
	margin-right: 20px;
	fill: $fulcro-gray;
}
