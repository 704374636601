@import '../../styles/variables.scss';
.modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 10;

	.modal-main {
		position: fixed;
		background: white;
		width: 80vh;
		max-height: 100vh;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 30px;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transition: opacity 1s ease;
		@media only screen and (max-width: $screen-md-min) {
			width: 100%;
			height: 100%;
		}
	}
}

.display-block {
	display: block;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	opacity: 1;
}

.display-none {
	display: none;
	opacity: 0;
}

.modal-close-btn {
	fill: #53565a;
	float: right;
	cursor: pointer;
}

.modal-line {
	width: 100%;
	height: 1px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: $fulcro-green;
}

.modal-title p {
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: left;
	@media only screen and (max-width: $screen-md-min) {
		font-size: 14px;
	}
}
.modal-info {
	text-align: left;
	font-size: 14px;
	color: $light-gray;
	p {
		text-align: left;
		font-size: 14px;
		color: $light-gray;
		margin-bottom: 8px;
	}
}

.modal-ul {
	margin-top: 10px;
	margin-left: 0;
	padding-left: 20px;
	list-style-type: disc;
}
