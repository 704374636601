@import '../../styles/variables.scss';

.popup__exist_porcess {
	align-items: center;
	border-radius: 0.625rem;
	display: flex;
	flex-direction: column;
	padding: 2.031rem 1.438rem 3.875rem 2.063rem;
	align-items: flex-start;
	&.error {
		background-color: $bg-error;
	}
	&.warning {
		background-color: $bg-warning;
	}
	&.successful {
		background-color: $bg-successful;
	}
	&__close {
		margin-left: auto;
	}
	h5 {
		text-align: center;
		color: $fulcro-black;
	}
	&__text {
		letter-spacing: 0.25px;
		line-height: 20px;
		margin: 0 auto 0.938rem 0;
		text-align: left;
		max-width: 14.375rem;
	}
	&__container__btns {
		display: flex;
		justify-content: flex-start;
		margin-right: auto;
	}
	&__btn {
		border: none;
		border-radius: 0.5rem;
		cursor: pointer;
		font-family: 'Tahoma-Bold', sans-serif;
		font-size: 0.875rem;
		letter-spacing: 0.75px;
		outline: none;
		padding: 0.625rem 1.125rem;

		&.warning {
			color: $white;
			background-color: $color-text-warning;
			&.ghost {
				color: $color-text-warning;
				border: 1px solid $color-text-warning;
				background-color: transparent;
				margin-right: 0.625rem;
			}
		}
		&.successful {
			color: $white;
			background-color: $color-successful;
			&.ghost {
				color: $color-successful;
				border: 1px solid $color-successful;
				background-color: transparent;
				margin-right: 0.625rem;
			}
		}
		&.error {
			color: $white;
			background-color: $color-error;
			&.ghost {
				color: $color-error;
				border: 1px solid $color-error;
				background-color: transparent;
				margin-right: 0.625rem;
			}
		}
	}
}

$animate-duration-fast: 0.4s;
$animate-duration: 1s;
$animate-duration-slow: 1.5s;

@-webkit-keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.8, 0.8, 0.8);
		transform: scale3d(0.8, 0.8, 0.8);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.8, 0.8, 0.8);
		transform: scale3d(0.8, 0.8, 0.8);
	}

	50% {
		opacity: 1;
	}
}

@mixin zoomIn {
	animation: $animate-duration-fast both zoomIn;
	-webkit-animation: $animate-duration-fast both zoomIn;
}

.pop__up__overlay {
	align-items: center;
	backdrop-filter: blur(7px);
	background: rgba(50, 69, 82, 0.65);
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	overflow-y: scroll;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;

	.pop__up__container {
		margin: 0 1.438rem;
		max-width: 20.688rem;
		width: 100%;

		@include zoomIn;
	}
}
