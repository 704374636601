@import '../../../styles/variables.scss';

.perfil-vehiculo-content {
	display: flex;
	padding-top: 32px;
	justify-content: space-between;
	flex-grow: 1;

	@media only screen and (max-width: $screen-md-min) {
		flex-direction: column;
		min-height: auto;
	}
}

.perfil-vehiculo-content h3 {
	color: $fulcro-gray;
	font-size: $font-25;
	letter-spacing: 0;
	line-height: 36px;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-18;
		line-height: 27px;
	}
}

.perfil-orange-title {
	font-size: $font-20;
	color: $fulcro-green;
	letter-spacing: 0.63px;
	line-height: 24px;
	font-family: 'Tahoma-Bold', sans-serif;
	margin-top: 30px;
	text-align: left;
	text-transform: uppercase;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-16;
		letter-spacing: 0.5px;
		line-height: 19px;
	}
}

.perfil-form-content {
	display: flex;
	flex-direction: column;

	@media only screen and (min-width: $screen-md-min) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 32px;
	}
}

.perfil-vehiculo-form-input {
	display: block;
	margin-top: 10px;
	width: 100%;
	color: $light-gray-2;
	text-align: left;
}
.perfil-vehiculo-legal-content {
	margin-top: 62px;
	p {
		font-weight: 300;
		font-size: 12px;
		line-height: 15px;
		color: $fulcro-black;

		letter-spacing: 0.4px;
		font-family: 'Lato-Light';
	}
}
.perfil-vehiculo-legal-content p {
	color: $fulcro-gray;
	font-family: 'Lato-Light';
	font-size: $font-14;
	text-align: left;
	max-width: 253.95px;
	line-height: 20px;
	margin: 20px auto 0 auto;

	@media only screen and (min-width: $screen-md-min) {
		max-width: 481px;
		margin: 20px auto 0 0;
	}
}

.perfil-vehiculo-direction-column {
	display: flex;
	flex-direction: column;
	border-right: $dot solid 1px;
	&:last-child {
		border-right: none;
	}
	@media only screen and (max-width: $screen-md-min) {
		border-right: none;
	}
}

.perfil-vehiculo-content > div {
	width: 100%;
	margin-bottom: 10px;
}

.perfil-vehiculo-left-content {
	margin-left: auto;
	margin-right: auto;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
	p {
		margin-bottom: 30px;
	}
}

.perfil-vehiculo-orange-btn {
	margin-top: 20px;
}
