@import '../../../styles/variables.scss';

header.header--mobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	z-index: 99;

	.menu-toggle {
		cursor: pointer;

		i {
			display: block;
			width: 24px;
			height: 24px;
			height: 24px;
			width: 24px;
			background-size: contain;
			background-image: url('../../../images/svg/lines-button.svg');
			background-repeat: no-repeat;

			&.close {
				background-image: url('../../../images/svg/close-btn.svg');
			}

			&.hidden {
				display: none;
			}
		}
	}

	&.active {
		.header__bar {
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
		}

		nav {
			height: auto;
			bottom: 0;
			overflow-y: auto;
		}
	}

	.header__bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 56px;
		padding: 0 $margin--mobile;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
	}

	.emergencia {
		display: none;
		justify-content: space-between;
		align-items: center;
		height: 56px;
		font-size: 0.875rem;
		font-family: 'Tahoma';

		&__links {
			white-space: nowrap;

			a,
			.link {
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				text-align: right;
				cursor: pointer;

				&:first-child {
					margin-left: 4px;
				}

				img {
					display: block;
				}

				&.close {
					margin-left: 1.081rem;

					img {
						display: inline-block;
						vertical-align: middle;
						filter: contrast(100) invert(1);

						&:hover {
							opacity: 80%;
						}
					}
				}
			}
		}
	}

	.emergencia.toast {
		display: flex;
		background: $fulcro-black;
		color: $white;
		padding: 0 18px;
	}

	nav {
		display: flex;
		position: fixed;
		overflow: hidden;
		left: 0;
		top: 112px;
		right: 0;
		bottom: 100%;
		z-index: -1;
		background-color: $white;
		padding: 0 1.875rem;
		flex-direction: column;
		justify-content: space-between;
		transition: bottom $slow, top $slow;

		.main-menu {
			margin-top: 7.137rem;

			& > ul {
				margin-bottom: 3.094rem;
				& > li {
					border-bottom: 1px solid #f8b530;
					margin-bottom: 24.62px;
					label {
						display: block;
						padding: 1.031rem 1.25rem;
						text-transform: uppercase;
						font-family: 'Tahoma-Bold', sans-serif;
						font-size: 1.125rem;
						color: $fulcro-black;
						line-height: 1;
						cursor: pointer;
						text-align: left;
						transition: color $fast;
						letter-spacing: 0.75px;
						&:hover {
							color: $fulcro-green-2;
						}
					}
				}
			}
		}
	}

	.logo {
		display: block;

		img {
			display: block;
			&.img__nav {
				height: 28px;
			}
		}
	}
}

[id^='drop'] {
	display: none;
}

body.emergencia-closed {
	header.header--mobile {
		.emergencia.toast {
			display: none;
		}

		.emergencia {
			display: flex;
		}
	}

	nav {
		top: 56px;
	}

	.App {
		margin-top: $header-bar--height--mobile;
	}
}
