@import '../../../styles/variables.scss';

.servicios-intro {
	padding: 40px 0;

	h4 {
		font-size: 1.5rem;
		color: $light-gray;
		font-weight: 400;
	}

	@media(min-width: $bp--md){
		margin-top: -80px;
		background-color: $white;
		box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);
		padding: 0;

		&__top{
			@media (min-width: $screen-md-min) {
				padding: 60px 100px;
			}
		}

		h1{
			@media(min-width: $bp--md){
				margin-bottom: 1rem;
			}
		}
	}
}

//Reused on top and bottom of services page
.products{	

	margin-top: 60px;
	
	a{
		display: flex;
		align-items: center;
		padding: 20px 0 20px 20px;
		border-width: 1px 0 0 0;
		border-style: solid;
		border-color: #C1C4C8;

		&:first-child{
			border: none;
		}

		h4{
			margin: 0;
			flex-grow: 1;
			font-size: 1.6rem;
			font-family: 'Tahoma-Bold', sans-serif;
		}


		.icon{
			width: 72px;
			height: 40px;
			object-fit: contain;
			margin-right: 20px;
		}

		.arrow{
			height: 32px;
			padding: 8px 16px;
		}
	}

		//MOBILE
		@media(max-width: $bp--md){
			box-shadow: 0 8px 32px rgba(21,13,0,0.1);
	
			a{
				padding: 32px 4px 32px 20px;
			}
	
		}
}



// Tablet >
@media(min-width: $bp--md){

	.products{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
		border-top: 1px solid #D8D8D8;
		margin-top: 0;
		
		a{
			border-width: 0 0 0 1px;
		}
	}
}
