.loadingContent {
	z-index: 10000;
	width: 100%;
	height: 127vh;
	background-color: rgba(0, 0, 0, 0.137);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		stroke: #006FA7;
	}
}
