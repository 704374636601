@import '../../styles/variables.scss';

.orange-title {
	color: $fulcro-green;
	font-family: 'Tahoma-Bold', sans-serif;
	font-size: 1.8rem;
	text-transform: uppercase;

	@media only screen and (min-width: $screen-md-min) {
		font-size: 2.4rem;
	}
}
