@import '../../../styles/variables.scss';

.pago-content {
	@media (min-width: $screen-md-min) {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
	}
}

.pago-content h3 {
	color: $fulcro-gray;
	margin-bottom: 20px;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-18;
		margin-bottom: 10px;
	}
}

.pago-direction-column {
	display: flex;
	flex-direction: column;
	border-right: $dot solid 1px;
	&:last-child {
		border-right: none;
	}
	@media only screen and (max-width: $screen-md-min) {
		border-right: none;
	}
}

.pago-content > div {
	width: 100%;
	margin-bottom: 10px;
	align-items: center;
}

.pago-p {
	color: $fulcro-gray;
	font-size: $font-16;
	padding: 0px;
	margin: 0px;
	margin-top: 18px;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-14;
		text-align: left;
	}
}

.pago-form-content {
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}

.pago-total {
	text-align: left;
	margin-top: 20px;
	@media only screen and (min-width: $screen-md-min) {
		margin-top: 4.375rem;
	}
}

.pago-orange-btn {
	margin-top: 60px;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 40px;
	}
}

.pago-grow {
	display: flex;
	flex-grow: 1;
	flex-wrap: wrap;
	margin: 0 -6px;

	.left {
		flex: 1 1 calc(33% - 12px);
		margin: 0 6px;
	}
	.right {
		flex: 0 1 calc(33% - 12px);
		margin: 0 6px;
	}
}

.pago-terminos-div {
	display: flex;
	width: 100%;
	margin-top: 40px;
}

.upper {
	text-transform: uppercase;
}

.iconInfo {
	width: auto;
	line-height: revert;
	vertical-align: bottom;
	margin-right: 0.4rem;
}
.p-title {
	margin: 0 0 1.2rem 0;
	color: $fulcro-black;
}
.p-pago {
	margin-bottom: 10px;
	font-size: 12px;
	line-height: 15px;
	display: flex;
	align-items: center;
	color: $fulcro-black;
}

.Input--empty {
	color: $fulcro-black !important;
}

.m-top {
	margin-top: 20px;

	@media only screen and (min-width: $screen-md-min) {
		margin: 20px 0 50px 0;
	}
}
.m-bottom {
	margin-bottom: 30px;
}
.order__summary {
	margin-top: 40px;
	@media only screen and (min-width: $screen-md-min) {
		margin-top: 50px;
	}
}
.card__info__container {
	@media only screen and (min-width: $screen-md-min) {
		column-gap: 1rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}
