@import '../../styles/variables.scss';
$animate-duration: 1s;

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@mixin fadeIn {
	animation: $animate-duration both fadeIn;
	-webkit-animation: $animate-duration both fadeIn;
}
.incluye {
	&__container {
		text-align: left;
		margin: 0 auto;
		width: max-content;
		h3,
		ul {
			font-size: 12px;
			line-height: 25px;
			letter-spacing: 0.25px;
			
			&.primary {
				color: white;
				font-size: 12px;
			}
			&.secondary {
				color: $fulcro-black;
				font-size: 12px;
			}
		}

		ul {
			li {
				&.pointer {
					cursor: pointer;
					position: relative;
					padding: 0 5px;
					color: white;
					margin: 5px 0;
					border-bottom: 1px solid #c6c7c8;

					&::after {
						transition: all 0.2s ease-in;
						content: url('../../images/svg/down-arrow.svg');
						position: absolute;
						right: 0;
						top: 0;
					}
					&.show {
						&::after {
							transform: rotate(180deg);
						}
					}
				}
				span.span__detail {
					display: flex;
					display: none;
					flex-wrap: wrap;
					max-width: 300px;
					&.show {
						@include fadeIn;
						display: flex;
					}
				}
			}
			&.primary {
				li {
					color: white;
					&:not(.pointer):before {
						content: url('../../images/svg/check_white.svg');
						margin-right: 6px;
					}
				}
			}
			&.secondary {
				li {
					&:before {
						content: url('../../images/svg/check.svg');
						margin-right: 6px;
					}
				}
			}
		}
	}
}
