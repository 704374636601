@import '../../styles/variables.scss';

.banner__primary {
	background-position: center 312px;
	background-repeat: no-repeat;
	padding-bottom: 198px;
	background-size: 560px 350px;

	@media only screen and (min-width: $bp--desktop-nav) {
		background-position: center 263px;
		background-repeat: no-repeat;
		padding-bottom: 503px;
		background-size: 1200px 600px;
	}
	.slide-content {
		padding: 75px 0 50px 0;
		max-width: 900px;
		margin: 0 auto;

		h1 {
			margin-bottom: 0.5em;
		}

		p {
			max-width: 230.06px;
			margin: 28.44px auto 34.99px auto;
			@media only screen and (min-width: $bp--desktop-nav) {
				max-width: 584px;
			}
		}

		.orange-button {
			margin-bottom: 1.5em;
		}
		.mas-info {
			margin: 1.375rem 0 0 0;
		}

		.button {
			width: 200px;
			margin-top: 1.375rem;
		}
	}
	&__images__contanier {
		max-width: 1200px;
		margin: 0 auto;
	}
}
