@import '../styles/variables.scss';

.banner a.mas-info {
	margin-top: 10px;
}

.experiencia-connect {
	max-width: 1200px;
	margin: 100px auto;
	display: flex;
	flex-direction: column;
	&-content {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		@media only screen and (min-width: $bp--desktop-nav) {
			flex-direction: row;
		}
	}
	.button {
		width: 200px;
		margin: 60px auto 0 auto;
		@media only screen and (min-width: $bp--desktop-nav) {
			margin: 0px 1rem 0 0;
		}
	}

	.container__btns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media only screen and (min-width: $screen-md-min) {
			flex-direction: row;
		}
	}
}

.by__connect {
	&__content {
		display: flex;
		flex-direction: column;
		max-width: 994px;
		margin: 0 auto;

		@media only screen and (min-width: $bp--desktop-nav) {
			flex-direction: row-reverse;
		}

		.button {
			max-width: 136px;
		}
	}
	&__container {
		padding: 45.35px 36.9px 99.07px 36.9px;
		background-color: $fulcro-black;

		@media only screen and (min-width: $bp--desktop-nav) {
			padding: 100px 36.9px 50px 36.9px;
		}
	}
	&__logos {
		display: flex;
		position: relative;
		justify-content: flex-end;
		margin: 0 0 30px auto;
		max-width: 448.447px;
		.fulcro {
			width: 448.447px;
			height: 318.399px;
			@media only screen and (max-width: $screen-md-min) {
				margin-right: -36.9px;
			}
		}
	}
	&__titles {
		color: $white;
		font-size: 35px;
		font-family: 'Tahoma-Bold';
		margin-bottom: 20px;

		@media only screen and (min-width: $screen-md-min) {
			font-size: 45px;
			line-height: 3.75rem;
		}
		p {
			color: $white;
			font-size: 20px;
		}
	}
	&__text {
		color: $white;
		max-width: 584px;
		margin: 0 auto 42.59px 0;
	}
}
