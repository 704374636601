@import '../../../styles/variables.scss';

.finalizar-content {
	display: flex;
	padding-left: 5%;
	padding-right: 5%;
	justify-content: space-between;
	flex-grow: 1;

	@media only screen and (max-width: $screen-md-min) {
		padding-bottom: 50px;
		padding-left: 5%;
		padding-right: 5%;
		min-height: auto;
		flex-direction: column-reverse;
	}
}

h1.finalizar__title {
	color: $fulcro-black;
}

.finalizar-icon {
	margin-bottom: 28px;
	text-align: left;
	@media only screen and (max-width: $screen-md-min) {
		text-align: center;
		margin-bottom: 30px;
	}
}

.finalizar-direction-column {
	display: flex;
	flex-direction: column;
	border-right: $dot solid 1px;
	& > img {
		width: 100%;
		max-width: 700px;
		height: auto;
	}
	&:last-child {
		border-right: none;
	}
	@media only screen and (max-width: $screen-md-min) {
		border-right: none;
	}
}

.finalizar-content > div {
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
}

.finalizar-content p,
.finalizar__p {
	font-size: $font-14;
	margin-top: 12px;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.44px;
	margin-bottom: 4px;
	&.small {
		font-size: 14px;
	}
}

.finalizar__line {
	margin: 12px 0;
}

.finalizar-necesitas-ayuda {
	font-family: 'Lato-Light';
	font-size: $font-14;
	line-height: 17px;
	text-align: center;
	margin: 26px auto 2rem auto;
	color: $fulcro-black;
	max-width: 169.63px;
}

.finalizar-contactenos {
	font-family: 'Lato-Light';
	font-size: $font-18;
	letter-spacing: 0;
	line-height: 28px;
	text-align: left;
	margin-top: 6px;
	color: $fulcro-green-2;
	display: block;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-16;
		text-align: center;
	}
}
.finalizar-left-content {
	width: 392px;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}

.finalizar-green-btn {
	margin-top: 60px;
	background-color: $green;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 40px;
	}
}

.-finalizar {
	.App {
		margin-top: 0 !important;
	}
}

.finalizar__margin {
	margin: 3.563rem 30px 20px 30px;
	text-align: center;
}

.finalizar__contact {
	margin-top: 20px;
	.spacing {
		margin: 0 15px;
	}
}
.illustration {
	p {
		font-family: 'Tahoma-Bold';
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.5px;
		text-align: center;
		margin: 100px 0 50px 0;
	}
}
.finalizar__container {
	margin: 0 auto 100px auto;
	max-width: 584px;
}

.finalizar-direction-column-img {
	@media only screen and (min-width: $screen-md-min) {
		max-width: 276px;
		margin: 0 auto;
	}
}
