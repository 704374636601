@import '../../../styles/variables.scss';

.servicios {
	padding: 145.63px 15px 44px;
	max-width: 1440px;
	margin: 0 auto;
	@media only screen and (min-width: $screen-md-min) {
		padding: 100px 120px;
	}
	.ct-container {
		max-width: 1280px;
	}

	.title-green {
		@media only screen and (min-width: $screen-md-min) {
			text-align: left;
		}
	}
}

.servicios__inner {
	display: flex;
	align-items: center;

	flex-wrap: wrap;

	& > div {
		width: 100%;
	}

	@media only screen and (min-width: $screen-md-min) {
		& > div {
			width: 50%;
		}
	}
}

.servicios-logo-falabella {
	justify-content: center;
	display: flex;
	margin: 84.39px auto 0 auto;
	@media only screen and (min-width: $screen-md-min) {
		margin: 30px auto 0 0;
		justify-content: flex-start;
	}
}

.servicios__right {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.servicios__left {
	margin: 4px auto 84px auto;
	max-width: 272px;

	@media only screen and (min-width: $screen-md-min) {
		margin: 4px 0 84px 0;
		max-width: 100%;
	}

	h2 {
		margin: 0 0 1rem 0;

		@media only screen and (max-width: $screen-md-min) {
			margin: 0 auto 1rem auto;
		}
	}

	p {
		margin-bottom: 4rem;
	}

	.button {
		width: 136px;
		margin: 0 auto;

		@media only screen and (min-width: $screen-md-min) {
			margin: 0 auto 0 0;
		}
	}
}

.servicios__item {
	height: 114px;
	display: flex;
	padding: 0 20px;
	background-color: $white;
	align-items: center;
	justify-content: flex-start;
	box-shadow: 0px 1px 10px 4px rgba(0, 0, 0, 0.1);
	width: 100%;
	border-bottom: 1px solid #51b9da;
	@media only screen and (min-width: $screen-md-min) {
		width: 481px;
		margin: 0 0 0 auto;
		justify-content: start;
	}

	&.moto {
		@media only screen and (min-width: $screen-md-min) {
			// Desktop
			margin: 40px 0 40px 16%;
		}

		.servicios__item__img {
			padding: 0 6px;
		}
	}

	&.home {
		border-bottom: none;

		.servicios__item__img {
			padding: 0 5px;
		}
	}

	&__img {
		width: 70px;
		margin-right: 20px;
	}

	h4 {
		font-family: 'Tahoma-Bold';
		color: $fulcro-black;
		font-size: $font-14;
		flex-grow: 1;
		margin: 0;
		line-height: 20px;
		font-weight: normal;
		letter-spacing: 0.5px;
	}
}
